<template>

  <div class="cash">

    <div class="cash__top">
      <div class="item__row item__ac">
        <i
            @click="$router.push('/admin_panel')"
            class="mdi mdi-folder-plus">
        </i>
        <!--        <i-->
        <!--            @click="show_order=true"-->
        <!--            class="mdi mdi-folder-plus">-->
        <!--        </i>-->
      </div>

      <i
          class="mdi mdi-logout"
          @click="$router.push('/kassa-login')"
      >

      </i>

    </div>

    <div class="cash__inner">
      <div class="products">


        <h3 class="mb-2">Категории</h3>
        <div
            class="cash__categories mb-4"
        >
          <div
              v-for="(category,category_index) in categories" :key="category_index"
              :class="{'cash__categories--item' :true, 'cash__categories--active': category_id === category.id  }"
              @click="getProductsByCategory(category.id)"
          >

            <p>{{ category.name }} </p>

          </div>

        </div>
        <p class="good__name">Товары</p>


        <input
            v-model="search"
            class="good__search"
            type="text"
            placeholder="Поиск..."
        >


        <div class="products__menu">
          <div

              class="product__item"

              v-for="(product,product_index) in products"
              :key="product_index"

              @click="addOrder(product)"
          >
            {{ product.name }}
          </div>
        </div>

        <v-pagination
            v-if="pageCount"
            v-model="page"
            :length="pageCount"
            :total-visible="3"
        ></v-pagination>
      </div>

      <div class="cassa">



        <v-dialog
            width="50%"
            v-model="show_bonus"
        >
          <v-card class="pa-10">
            <v-text-field
                v-model="amount_bonus"
                outlined
                label="Сумма"
            >

            </v-text-field>
            <p v-if="bonus_message">{{ bonus_message }}</p>

            <v-btn class="primary" @click="subBonusPart">Списать</v-btn>
          </v-card>
        </v-dialog>
        <v-dialog
            width="50%"
            v-model="show_loan"

        >
          <v-card
              class="pa-10"
          >
            <div class="">
              <h3 class="mb-4">Долг</h3>

              <v-text-field
                  v-model="name"
                  outlined
                  label="Имя"
              >

              </v-text-field>
              <v-text-field
                  v-model="loan"
                  outlined
                  label="Сумма"
              >
              </v-text-field>


              <masked-input
                  class="sms__digits mb-2"
                  placeholder="8 XXX XXX XX XX"
                  v-model.trim="phone_loan"
                  mask="8(111) 111-11-11"
                  ref="numericInput"
                  type="tel"
                  outlined
              />

              <p

                  @click="open_date_picker=true"
                  class="loan__back"
              >
                <i class="mdi mdi-calendar mr-2"></i>
                Дата возвращение долга
              </p>

              <h3>{{ date }}</h3>

            </div>
          </v-card>


        </v-dialog>

        <v-dialog width="290" v-model="open_date_picker">
          <v-card>
            <v-date-picker v-model="date" locale="ru-Ru"></v-date-picker>
          </v-card>
        </v-dialog>
        <div
            v-for="(order,i) in orders" :key="i"
            class="product__added">

          <div
              class="product__order--item"
          >
            <h3>{{ order.name }}</h3>
            <p class="mb-4">{{ order.price }}
              <span v-if="order.count_type === 'count'">тнг/шт</span>
              <span v-else-if="order.count_type === 'kg'">тнг/кг</span>
              <span v-else-if="order.count_type === 'litr'">тнг/л</span>
            </p>

            <div
                v-if="order.count_type === 'count'"
                class="product__action">
              <div
                  @click="subOrderCount(order)"
                  class="product__plus">-
              </div>

              <p>{{ order.count_value ? order.count_value : 1 }}</p>
              <div
                  @click="addOrderCount(order)"
                  class="product__plus">+
              </div>
            </div>
            <div
                v-else
                class="product__action">
              <input placeholder="кг|литр" @input="changeMass(order)" v-model="order.amount_value"
                     type="number">
            </div>


            <p v-if="order.order_price">{{ order.order_price }} тнг</p>


            <div
                @click="deleteElement(order.id)"
                class="product__delete"
            >
              <i class="mdi mdi-delete-forever"></i>
            </div>
          </div>

        </div>

        <div>

        </div>


        <div v-if="orders.length!==0">
          <div
              class="payment__types mb-4">

            <v-switch
                v-if="orders.length !==0"
                v-model="give_bonus"
                color="green"
                label="Накопить бонус"
                hide-details
                class="mb-6"
            ></v-switch>

            <masked-input
                v-if="give_bonus"
                class="sms__digits mb-2"
                placeholder="8 XXX XXX XX XX"
                v-model.trim="phone"
                mask="8(111) 111-11-11"
                ref="numericInput"
                type="tel"
                outlined
                @input="searchBonus"
            />


            <p class="mb-2" v-if="bonus.length !== 0 && bonus !=='Не найдено'"><b>Накопленный бонус: </b>{{ bonus[0].bonus }} тнг</p>
            <p class="bonus_not_found mb-6" v-if="bonus ==='Не найдено'"><b>Номер не найдено</b></p>




            <div class="item__row item__ac">
              <v-btn
                  v-if="bonus.length !== 0 && bonus !=='Не найдено' && bonus[0].bonus !== 0"
                  type="submit"
                  class="mb-4 mr-5 button"
                  value="Списать весь бонус"
                  @click="subBonus(bonus[0].bonus)"
              >
                <p>Списать весь бонус</p>
              </v-btn>

              <v-btn
                  v-if="bonus.length !== 0 && bonus !=='Не найдено' && bonus[0].bonus !== 0 "
                  type="submit"
                  class="mb-4 button"
                  @click="show_bonus=true"
              >
                Списать частично
              </v-btn>
            </div>



            <div
                v-for="(p,p_index) in payments"
                class="item__row item__ac payment__block"
                :key="p_index"
            >
              <div
                  class="payment__column item__column"
              >
                <v-select

                    :items="p.payment_types"
                    item-text="label"
                    item-value="value"
                    label="Выберите тип оплаты"
                    v-model="p.selected_payment"
                    outlined
                    hide-details
                    class="mb-4"
                ></v-select>

                <v-text-field
                    v-if="p.selected_payment==='nal' || payments.length > 1"
                    v-model="p.amount "
                    outlined
                    label="Сумма"
                    @input="setPaymentValue(p_index,p.amount)"
                    hide-details
                >
                </v-text-field>
              </div>
              <i
                  @click="addPayment()"
                  class="mdi mdi-plus">
              </i>
              <i
                  @click="removePayment(p_index)"
                  class="mdi mdi-minus">
              </i>
            </div>
          </div>
          <div
              v-if="orders.length!==0"
              class="payment mb-4">
            <p>К оплате </p>
            <p>{{ amount_of_order }} тнг</p>
          </div>



          <p v-if="totalAmount() > amount_of_order" class="mb-4 for_delivery">К сдаче: <span class="for_delivery_value"><b>{{ totalAmount()-amount_of_order }} тнг </b></span></p>

          <div
              @click="saveOrder"
              class="payment__pay"
          >
            <p>Оплатить</p>
          </div>
        </div>

      </div>
    </div>


  </div>
</template>
<script>
import {debounce} from 'lodash';
import MaskedInput from "vue-masked-input";

export default {
  name: "CashInterface",
  components: {
    MaskedInput
  },
  data() {
    return {
      show_bonus: false,
      amount_bonus: '',
      bonus: '',
      phone_loan: '',
      open_date_picker: false,
      date: '',
      category_id: '',
      give_bonus: false,
      name: '',
      pageCount: 0,
      page: 1,
      categories: [],
      payment_types: [],
      payments: [
        {
          payment_types: [],
          amount: 0,
          selected_payment: ''
        }
      ],
      orders: JSON.parse(localStorage.getItem('orders')) || [], // Initialize properly
      products: [],
      selected_payment: '',
      amount_of_order: '',
      search: '',
      show_order: false,
      phone: '',
      show_loan: false,
      loan: '',
      bonus_message: '',
      amount_pay: '',
      has_many_payment: false
    }
  },
  mounted() {

    if(localStorage.getItem('access_token')) {
      this.getPayments()
      this.getCategories()
    }
  },
  methods: {
    allPaymentsSelected() {
      return this.payments.every(payment => payment.selected_payment !== '');
    },
    setPaymentValue(index,amount) {
      if(index === 0)  {
        this.payments[1].amount = this.amount_of_order - amount
      }
    },
    removePayment(index) {
      this.payments.splice(index, 1);
    },
    totalAmount() {
      return this.payments.reduce((sum, payment) => sum + Number(payment.amount), 0);
    },
    addPayment() {

      if(this.totalAmount() > this.amount_of_order) {
        return false
      }
      this.payments.push(
          {
            payment_types: this.payment_types,
            amount: 0,
            selected_payment: ''
          }
      );
    },
    subBonusPart() {
      if(this.amount_bonus > this.bonus[0].bonus) {
        this.bonus_message = 'Сумма привышает'
        return false;
      }
      this.bonus[0].bonus = this.bonus[0].bonus - this.amount_bonus

      this.amount_of_order = this.amount_of_order - this.amount_bonus
      this.show_bonus = false
    },
    subBonus(bonus) {
      this.amount_of_order = this.amount_of_order - bonus
      this.bonus[0].bonus = 0
    },
    searchBonus(val) {

      if (val.replace(/\D/g, "").length === 11) {
        this.getAmountOfOrder()
        this.$axios({
          method: 'get',
          url: `${this.$API_URL}bonus/search?search=${val.replace(/\D/g, "")}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          }
        })
        .then((res) => {
            this.bonus = res.data
            if(res.data.length === 0) {
              this.bonus = 'Не найдено'
            }
        })
        .catch((error) => {
          console.log(error)
        });
      }


    },
    onDecode(result) {
      console.log('Decoded Result:', result.length);
    },
    onLoaded(result) {
      console.log("rr", result)
    },
    debounceSearchProduct: debounce(function () {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?search=${this.search}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data
            this.pageCount = res.data.last_page
            localStorage.setItem('sales', JSON.stringify(this.products))
          })
          .catch((error) => {
            console.log(error)
          });
    }, 1000),
    searchProduct() {

      this.debounceSearchProduct();

    },
    saveOrder() {

      const hasZeroAmount = this.orders.some(order => order.count_type !== 'count' && order.amount_value === 0);

      if(this.payments.length >1 && (this.totalAmount() > this.amount_of_order || this.totalAmount() < this.amount_of_order)) {
        this.$toast.open({
          message: 'Не правильная оплата',
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        })
        return false
      }
      if(this.orders.length ===0 ) {
        this.$toast.open({
          message: 'Выберите товары',
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        })
        return false
      }
      if (hasZeroAmount) {
        this.$toast.open({
          message: 'Укажите вес или литр',
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
        return false;
      }
      if (!this.allPaymentsSelected()) {
        this.$toast.open({
          message: 'Выберите тип оплаты',
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });

        return false;

      }

      if (this.give_bonus && this.phone.replace(/\D/g, "").length !== 11) {
        this.$toast.open({
          message: 'Телефон не заполнено',
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });

        return false;

      }



      let data = {
        orders: this.orders,
        total: this.amount_of_order,
        payment_type: this.selected_payment,
        loan: this.loan,
        phone_loan: this.phone_loan,
        date: this.date,
        phone: this.phone.replace(/\D/g, ""),
        give_bonus: this.give_bonus,
        name: this.name,
        show_loan: this.show_loan,
        bonus: this.bonus.length ? this.bonus[0].bonus : '',
        payments: JSON.stringify(this.payments)
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}order`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.selected_payment = ''
            this.orders = []
            this.give_bonus = false

            this.payments = [
              {
                payment_types: [],
                amount: 0,
                selected_payment: ''
              }
            ];
          })
          .catch((error) => {
            console.log(error)
          });
    },
    getAmountOfOrder() {
      this.amount_of_order = this.orders.reduce((total, order) => total + order.order_price ?? 0, 0);

      this.payments[0].amount = this.amount_of_order
    },
    deleteElement(id) {
      const index = this.orders.findIndex(order => order.id === id);
      if (index !== -1) {

        for (let i = 0; i < this.products.length; i++) {
          this.products[i].count_value = 1
          this.products[i].order_price = parseInt(this.products[i].price)
          this.products[i].amount_value = 0
        }

        this.orders.splice(index, 1);
      }
    },
    changeMass(order) {
      console.log(order)

      if (order.quantity < order.amount_value) {
        alert('На складе недостаточно')
        order.amount_value = order.quantity;
      }

      this.$set(order, 'order_price', order.amount_value * order.price);


    },
    addOrderCount(order) {

      if (order.quantity === 0 || order.quantity < 0)
        return false;
      if (order.quantity === 1) {
        this.$set(order, 'count_value', 1);
        return false;
      }
      if (!order.count_value) {
        this.$set(order, 'count_value', 2);
        return false;
      } else {
        order.count_value = order.count_value + 1
      }

      order.order_price = order.count_value * order.price
    },
    subOrderCount(order) {

      if (!order.count_value) {
        this.$set(order, 'count_value', 1);
        return false;
      } else if (order.count_value <= 1) {
        order.count_value = 1
        return false;
      } else {
        order.count_value = order.count_value - 1
      }


      order.quantity = order.quantity - order.count_value
      order.order_price = order.order_price - 1 * order.price
    },
    getProductsByCategory(id, page = null) {
      this.category_id = id
      if (!page)
        page = 1

      this.$axios({
        method: 'get',
        url: `${this.$API_URL}product?category_id=${id}&page=${page}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.products = res.data.data
            this.pageCount = res.data.last_page
            localStorage.setItem('products', JSON.stringify(this.products))
          })
          .catch((error) => {
            console.log(error)
          });
    },
    getCategories() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}category`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.categories = res.data
          })
          .catch((error) => {
            console.log(error)
          });
    },
    addOrder(product) {


      this.$set(product, 'amount_value', 0);
      this.$set(product, 'count_value', 1);
      if (!product.order_price) {
        if (product.count_type === 'count')
          this.$set(product, 'order_price', parseInt(product.price));
        else
          this.$set(product, 'order_price', 0);
      }


      if (!this.orders.some(order => order.id === product.id)) {
        this.orders.push(product)
      }


    },
    getPayments() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}payments`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            console.log(res.data)
            this.payment_types = res.data
            this.payments[0].payment_types = res.data
            console.log(this.payment_types)
          })
          .catch((error) => {
            console.log(error)
          });
    }
  },
  watch: {
    selected_payment(val) {
      console.log("selected payment -" + val)

      if (val === 'qaryz') {
        this.show_loan = true
      }
    },
    search(val) {
      if (val) {
        this.searchProduct();
      } else {
        this.getProductsByCategory(this.category_id, 1);
      }
    },
    page(newPage) {
      this.getProductsByCategory(this.category_id, newPage);
    },
    orders: {
      handler() {
        console.log('Orders array changed');
        console.log('New total order price:', this.getAmountOfOrder());

        localStorage.setItem('orders', JSON.stringify(this.orders))
      },
      deep: true,
      immediate: true
    }
  }

};
</script>

<style lang="scss" scoped>

p {
  padding: 0 !important;
  margin-bottom: 0 !important;
}


.cash__top {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
}

.cash__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cash {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;

  .products {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100vh;

    .good__name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px !important;
    }

    .good__search {
      padding: 10px;
      border: 1px solid black;
      margin-bottom: 20px;
    }


  }

}

.cassa {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: 20px;
  height: 100vh;
  overflow-y: scroll;

  .product__added {
    margin-bottom: 20px;
    position: relative;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .product__name {
      margin-bottom: 10px !important;
      font-weight: bold;
    }

    .product__action {
      width: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 10px !important;


      input {
        border-radius: 5px;
        padding: 5px;
        border: 1px solid black;
        width: 100px;
      }

      .product__plus {
        cursor: pointer;
        padding: 10px;
        background: #0069ff;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 5px;
        font-size: 18px;
      }
    }
  }
}


.payment__pay {
  cursor: pointer;
  background: #ffa129;
  padding: 20px;
  border-radius: 5px;
  color: white;
  font-size: 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: uppercase;

}

.payment {
  cursor: pointer;
  border: 1px solid black;
  padding: 20px;
  border-radius: 5px;
  font-size: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: black;

}

.payment__types {

  .payment__type--title {
    font-size: 20px;
    font-weight: bold;
  }

  p {
    padding: 5px !important;
    margin-bottom: 2px !important;

  }
}

.product__delete {

  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  i {
    font-size: 18px;
    color: red;
  }
}

.product__order--item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}


.cash__categories::-webkit-scrollbar {
  width: 1px;
}

.cash__categories::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
}

.cash__categories::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.cash__categories {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;

  .cash__categories--item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
    padding: 30px;
    border-radius: 5px;

    border: 1px solid #0069ff;
  }
}

.products__menu {
  display: flex;
  flex-wrap: wrap;

  .product__item {
    padding: 20px;
    cursor: pointer;
    border-radius: 5px;

    border: 1px solid #ccc;
    margin-right: 10px;
    width: 100px;
  }
}

.cash__categories--active {
  background: #ffc64a;
  color: white;
}

.sms__digits {
  border: 1px solid gray;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 5px;
}

.loan__back {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 15px !important;
  color: #0069ff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loan__back:hover {
  opacity: 0.8;
}

.bonus_not_found {
  color: #a71d2a;
}

.for_delivery {
  font-size: 30px;
  font-weight: bold;
  color: dimgray;
}

.for_delivery_value {
  color: #0069ff;
}

.mdi-plus, .mdi-minus {
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 15px;
}
.payment__block {
  margin-bottom: 10px;
  border: 1px solid black;
  padding: 10px;
}
</style>